import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ButtonCopyToClipboardComponent } from '../../../../common-frontend/components/buttonCopyToClipboard/buttonCopyToClipboard';
import { HorizontalListComponent } from '../../../../common-frontend/components/horizontalList/horizontalList';
import { Spanner } from '../../../../common-frontend/components/micro/spanner/spanner';

import './sitePostCreationPopup.css';

interface SitePostCreationPopupProps {
  show: boolean;
  onClose: () => void;
}

export function SitePostCreationPopupComponent({ show, onClose }: SitePostCreationPopupProps): JSX.Element {

  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="site-post-creation-popup-component">
      <Modal
        show={show}
        centered
      >
        <Modal.Header>
          <Modal.Title>{t('firstStepDone')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <h5 style={{ marginBottom: "var(--size-8)" }}>{t('yourPageIsReady')}</h5>
          <p>{t('accessPageAnywhere')}</p>
          <div style={{ marginBottom: "var(--size-8)" }}>
            <HorizontalListComponent>
              <div><i>www.pixelvaidoso.com{location.pathname}</i></div>
              <Spanner />
              <ButtonCopyToClipboardComponent
                size='sm'
                valueToCopy={`www.pixelvaidoso.com${location.pathname}`}
              />
            </HorizontalListComponent>
          </div>

          <div
            style={{
              marginBottom: "var(--size-8)"
            }}
          >
            <p><b>{t('whatToDoNext')}</b></p>
            <p>{t('pageEditingGearText1')} <i className="bi bi-gear" /> {t('pageEditingGearText2')}</p>
          </div>

          <div>
            <p>{t('contactForSupport')}</p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onClose}>{t('close')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
