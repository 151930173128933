import { useState } from "react";
import { Container, Offcanvas } from "react-bootstrap";
import classNames from "classnames";
import { SitePageRenderInfo } from "../../common-app/sites/models/sitePageRenderInfo";
import { HorizontalListComponent } from "../../common-frontend/components/horizontalList/horizontalList";
import { Spanner } from "../../common-frontend/components/micro/spanner/spanner";
import { PageTitle } from "../../common-frontend/components/pageTitle/pageTitle";
import { DynamicSiteFooterComponent } from "../../components/sites/rendering/dynamicSiteFooter/dynamicSiteFooter";
import { DynamicSiteHeaderComponent } from "../../components/sites/rendering/dynamicSiteHeader/dynamicSiteHeader";
import { DynamicSiteTreeNavigationComponent } from "../../components/sites/rendering/dynamicSiteTreeNavigation/dynamicSiteTreeNavigation";
import { DynamicPageComponentProps } from "../../components/sites/rendering/dynamicPageContentsRenderingMapping";
import StyleManagerComponent from "../../components/sites/rendering/styleManager/styleManager";
import { SiteInfo } from "../../common-app/sites/models/siteInfo";

import './dynamicPageRenderer.css';

interface DynamicPageRendererProps {
  siteInfo: SiteInfo;
  pageRenderInfo: SitePageRenderInfo;
  ComponentToRender: React.ComponentType<DynamicPageComponentProps>
}

export function DynamicPageRenderer({
  siteInfo,
  pageRenderInfo,
  ComponentToRender }: DynamicPageRendererProps): JSX.Element {

  const [showNavigationPanel, setShowNavigationPanel] = useState<boolean>(false);

  const onShowNavigationPanel = () => {
    setShowNavigationPanel(!showNavigationPanel);
  }

  const onNavigationItemClicked = () => {
    setShowNavigationPanel(false);
  }

  return <>
    <div className="dynamic-page-renderer">

      <StyleManagerComponent
        primaryColor={siteInfo.mainColor}
        accentColor={siteInfo.backColor}
      />

      {/* Header */}
      <DynamicSiteHeaderComponent
        onShowMenu={onShowNavigationPanel}
      />

      <div className="content-container">
        <Container>
          <HorizontalListComponent>
            <Spanner />

            {/* Navigation */}
            <div className="side-navigation-container"
            >
              <DynamicSiteTreeNavigationComponent
                onNavigationItemClicked={onNavigationItemClicked} />
            </div>

            <Offcanvas
              className="off-canvas-panel"
              show={showNavigationPanel} onHide={() => { setShowNavigationPanel(false) }}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  className="off-canvas-panel-title"
                >{pageRenderInfo.siteName}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <DynamicSiteTreeNavigationComponent
                  onNavigationItemClicked={onNavigationItemClicked} />
              </Offcanvas.Body>
            </Offcanvas>

            {/* Content */}
            <Container>
              {pageRenderInfo.isHomePage === false &&
                <PageTitle title={pageRenderInfo.pageName} />
              }
              <div
                className={classNames({ "page-content-home": pageRenderInfo.isHomePage })} >
                <ComponentToRender
                  pageRenderInfo={pageRenderInfo}
                />
              </div>
            </Container>
            <Spanner />
          </HorizontalListComponent>
        </Container>
      </div>
    </div>

    {/* Footer */}
    <DynamicSiteFooterComponent />
  </>
}