import { useTranslation } from "react-i18next";
import { ContainerLabeledComponent } from "../../../../../common-frontend/components/containerLabeled/containerLabeled";
import { ColorPickerComponent } from "../../../../../common-frontend/components/colorPicker/colorPicker";
import { useEffect, useState } from "react";
import { VerticalListComponent } from "../../../../../common-frontend/components/verticalList/verticalList";
import { HorizontalListComponent } from "../../../../../common-frontend/components/horizontalList/horizontalList";
import { AutoSaveDataParams, useAutoSave } from "../../../../../common-frontend/hooks/autoSave/autoSave";
import { SiteColors } from "../../../../../common-app/sites/models/siteColors";
import { ServiceApiSites } from "../../../../../services/api/serviceApiSites";
import { TypeUpdate } from "../../../../../common/util/types/typeUpdate";

interface SiteSettingsColorsProps {
  siteId: string;
}

export function SiteSettingsColorsComponent({ siteId }: SiteSettingsColorsProps): JSX.Element {

  const { t } = useTranslation();

  const handleSaveSiteColors = (data: SiteColors, saveParams: AutoSaveDataParams): boolean => {

    ServiceApiSites.updateSiteColors(
      siteId,
      data.mainColor,
      data.backColor
    ).then((result) => {

      if (data && result) {
        setPreviousSiteColorsData(data);
      }
    });

    return true;
  }

  const {
    data: siteColors,
    updateData: updateSiteColors
  } = useAutoSave<SiteColors>(handleSaveSiteColors);

  const [previousSiteColorsData, setPreviousSiteColorsData] = useState<SiteColors>();

  useEffect(() => {

    const loadSiteData = async () => {
      const siteInfo = await ServiceApiSites.getSiteInfo(siteId);
      if (siteInfo) {
        updateSiteColors({
          mainColor: siteInfo.mainColor,
          backColor: siteInfo.backColor
        }, true);
        setPreviousSiteColorsData(siteColors);
      }
    }

    loadSiteData();
  }, [siteId]);

  if (siteColors === undefined) {
    return <></>;
  }

  return (
    <div className="site-settings-colors-component">
      <ContainerLabeledComponent
        label={t('site-colors')}
        uniqueId={`site-colors-${siteId}`}
      >
        <VerticalListComponent>
          <HorizontalListComponent>
            <label
              className='label'
              htmlFor="site-main-background-color"
            >
              {t('site-main-color')}
            </label>

            <ColorPickerComponent
              id="site-main-background-color"
              value={siteColors.mainColor}
              numberOfLatestColors={0}
              onColorChange={(value) => {
                updateSiteColors(TypeUpdate.updateObjectField(siteColors, 'mainColor', value));
              }}
              fixPosition={true}
            />

          </HorizontalListComponent>

          <HorizontalListComponent>

            <label
              className='label'
              htmlFor="site-back-background-color"
            >
              {t('site-back-color')}
            </label>

            <ColorPickerComponent
              id="site-back-background-color"
              value={siteColors.backColor}
              numberOfLatestColors={0}
              onColorChange={(value) => {
                updateSiteColors(TypeUpdate.updateObjectField(siteColors, 'backColor', value));
              }} fixPosition={true}
            />
          </HorizontalListComponent>
        </VerticalListComponent>
      </ContainerLabeledComponent>
    </div>
  );
}