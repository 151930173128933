import StyleManagerComponent from './styleManager';
import { SiteSecondaryColor, SiteThemeColor } from '../../../../common-app/siteThemeColor';

export function StyleManagerPixelVaidosoComponent(): JSX.Element {

  return <StyleManagerComponent
    primaryColor={SiteThemeColor}
    accentColor={SiteSecondaryColor}
  />
}
