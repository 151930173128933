import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { StyleManagerPixelVaidosoComponent } from "../../../components/sites/rendering/styleManager/styleManagerPixelVaidoso";

import './dynamicPageNotFound.css';

interface DynamicPageNotFoundProps {
  fullPath: string;
  siteDomain: string;
  pagePath: string;
}

export function DynamicPageNotFoundComponent({ fullPath, siteDomain, pagePath }: DynamicPageNotFoundProps): JSX.Element {

  const { t } = useTranslation();

  return <Container className="dynamic-page-not-found-component">
    <StyleManagerPixelVaidosoComponent />
      
    <div className="items-list">
      <div>
        <h1>{t('pageNotFound')}</h1>
        <h4>{fullPath}</h4>
      </div>

      <div>
        <div>{t('pageNotFoundMessage1')}</div>
        <div>{t('pageNotFoundMessage2')}</div>
      </div>

      <div>
        <Link to={`/sites/${siteDomain}/`}><h5>{t('siteHomePage')}</h5></Link>
        <Link to={`/`}><h5>Pixel Vaidoso</h5></Link>
      </div>
    </div>

  </Container>
}