import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslationCommonFrontEnd } from '../../../../../translations/translation';
import {
  GetImagesListFunction,
  UploadImageFunction
} from '../../../../imageUploader/imageBrowser';
import { ImageUploaderModalComponent } from '../../../../imageUploader/imageUploaderModal';

interface ElementConfigurationSnippetImageSelectionProps {
  uploadImageFunction: UploadImageFunction,
  getImagesListFunction: GetImagesListFunction,
  imageSelected: string,
  onImageSelected: (image: string) => void
}

export function ElementConfigurationSnippetImageSelectionComponent({
  uploadImageFunction,
  getImagesListFunction,
  imageSelected,
  onImageSelected,
}: ElementConfigurationSnippetImageSelectionProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const [showDialog, setShowDialog] = useState(false);

  const handleImageSelect = (images: string[]) => {

    if (images.length < 1) {
      return;
    }

    onImageSelected(images[0]);
  }

  return (
    <div className="element-configuration-snippet-image-selection-component">
      <Button
        onClick={() => {
          setShowDialog(true);
        }}
      >
        {t('chooseImage')}
      </Button>

      <ImageUploaderModalComponent
        imagesSelected={[imageSelected]}
        uploadImageFunction={uploadImageFunction}
        getImagesListFunction={getImagesListFunction}
        getImagesInfoFunction={() => { return Promise.resolve([]) }}
        showDialog={showDialog}
        allowMultipleSelection={false}
        allowEmptySelection={false}
        onClose={() => {
          setShowDialog(false)
        }}
        onConfirm={(images) => {
          handleImageSelect(images);
          setShowDialog(false)
        }}
      />
    </div>
  );
}
