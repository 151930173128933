import { useEffect, useState } from "react";
import { Contact } from "../../../../../common-app/sitePagesContacts/models/contact";
import { useTranslationCommonFrontEnd } from "../../../../translations/translation";
import { MapGoogleComponent } from "../../../map/mapGoogle";

import "./contact.css";

interface ContactComponentProps {
  contact: Contact;
}

export function ContactComponent({ contact }: ContactComponentProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const [showMails, setShowMails] = useState<boolean>(false);
  const [showPhones, setShowPhones] = useState<boolean>(false);
  const [showAddress, setShowAddress] = useState<boolean>(false);

  useEffect(() => {

    let showMails = false;
    let showPhones = false;
    let showAddress = false;

    // Show the mails if there are any
    if (contact.mail01 || contact.mail02 || contact.mail03) {
      showMails = true;
    }

    // Show the phones if there are any
    if (contact.phone01 || contact.phone02 || contact.phone03) {
      showPhones = true;
    }

    // Show the address if there are any
    if (contact.addressLine01 || contact.addressLine02 || contact.addressLine03 || contact.addressLine04) {
      showAddress = true;
    }

    setShowMails(showMails);
    setShowPhones(showPhones);
    setShowAddress(showAddress);
  }, [contact]);

  const getAddressFromContact = (contact: Contact): string => {
    return `${contact.addressLine01}, ${contact.addressLine02}, ${contact.addressLine03}, ${contact.addressLine04}`;
  }

  return (
    <>
      {(showMails || showPhones || showAddress) &&
        (
          <div className="component-contact">
            <div className="contact-data">
              <h2>{contact.name}</h2>
              <div className="contact-groups">
                {
                  showMails && (
                    <div>
                      <div className='contact-group-name'>{t('email')}</div>
                      <div className='value'>{contact.mail01}</div>
                      <div className='value'>{contact.mail02}</div>
                      <div className='value'>{contact.mail03}</div>
                    </div>
                  )
                }
                {
                  showPhones && (
                    <div>
                      <div className='contact-group-name'>{t('phone')}</div>
                      <div className='value'>{contact.phone01}</div>
                      <div className='value'>{contact.phone02}</div>
                      <div className='value'>{contact.phone03}</div>
                    </div>
                  )
                }
                {
                  showAddress && (
                    <div>
                      <div className='contact-group-name'>{t('address')}</div>
                      <div className='value'>{contact.addressLine01}</div>
                      <div className='value'>{contact.addressLine02}</div>
                      <div className='value'>{contact.addressLine03}</div>
                      <div className='value'>{contact.addressLine04}</div>
                    </div>
                  )
                }
              </div>
            </div>
            {
              (
                showAddress &&
                contact.latitude &&
                contact.longitude &&
                contact.showMap
              ) ? (
                <div
                  className="contact-map">
                  <MapGoogleComponent
                    latitude={contact.latitude}
                    longitude={contact.longitude}
                    address={getAddressFromContact(contact)}
                  ></MapGoogleComponent>
                </div>
              ) : null
            }
          </div>
        )
      }
    </>
  )
}