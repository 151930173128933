import { RequestBody } from "../../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../../common/api/requests/routeDefinitionPermissions";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";

export class UpdateSiteColorsRouteDef extends IRouteDefinition {
  constructor() {
    super("PUT");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(siteId: string): string {
    return `sites/update-site-colors?` + QueryParamsUtil.build({ "site-id": siteId });
  }

  public routeTemplate(): string {
    return 'sites/update-site-colors';
  }
}

export const enum UpdateSiteColorsQueryParams {
  siteId = 'site-id',
}

export class UpdateSiteColorsRequestBody extends RequestBody {
  constructor(
    public mainColor: string,
    public backColor: string
  ) {
    super();
  }
}

