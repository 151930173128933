import { useEffect } from 'react';
import { ColorSetter } from '../colorPickerTester/colorSetter';

type StyleManagerProps = {
  primaryColor: string; // e.g., '#007bff'
  accentColor: string;  // e.g., '#28a745'
};

function StyleManagerComponent({
  primaryColor,
  accentColor }: StyleManagerProps): null {

  useEffect(() => {

    ColorSetter.UpdateColors(primaryColor, accentColor);

  }, [primaryColor, accentColor]);

  return null; // This component only manipulates the DOM; it doesn't render anything
}

export default StyleManagerComponent;
