import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslationCommonFrontEnd } from '../../../../../translations/translation';
import { ColorPickerComponent } from '../../../../colorPicker/colorPicker';
import { HorizontalListComponent } from '../../../../horizontalList/horizontalList';
import { VerticalListComponent } from '../../../../verticalList/verticalList';
import { DisplayBlockOptions } from '../../../types';

import './elementConfigurationDisplayBlockOptions.css';

interface ElementConfigurationDisplayBlockOptionsProps {
  displayOptions: DisplayBlockOptions | undefined;
  setDisplayOptions: (displayOptions: DisplayBlockOptions) => void;
}

export function ElementConfigurationDisplayBlockOptionsComponent({
  displayOptions,
  setDisplayOptions
}: ElementConfigurationDisplayBlockOptionsProps): JSX.Element {

  const getUseBackgroundFlag = (displayOptions: DisplayBlockOptions | undefined): boolean => {
    if (!displayOptions) {
      return false;
    }

    if (displayOptions.backgroundColor === undefined) {
      return false;
    }

    return displayOptions.backgroundColor !== 'transparent';
  }

  const getBackgroundColor = (displayOptions: DisplayBlockOptions | undefined): string => {
    if (displayOptions === undefined) {
      return '#FFFFFF';
    }
    if (displayOptions.backgroundColor === undefined) {
      return '#FFFFFF';
    }
    return displayOptions.backgroundColor;
  }

  const getUseBorderFlag = (displayOptions: DisplayBlockOptions | undefined): boolean => {
    if (!displayOptions) {
      return false;
    }
    if (displayOptions.borderWidth === undefined) {
      return false;
    }

    return displayOptions.borderWidth > 0;
  }

  const getBorderWidth = (displayOptions: DisplayBlockOptions | undefined): number => {
    if (!displayOptions) {
      return 1;
    }
    if (displayOptions.borderWidth === undefined) {
      return 1;
    }

    return Math.max(1, displayOptions.borderWidth);
  }

  const getBorderColor = (displayOptions: DisplayBlockOptions | undefined): string => {
    if (!displayOptions) {
      return '#000000';
    }
    if (displayOptions.borderColor === undefined) {
      return '#000000';
    }

    return displayOptions.borderColor;
  }

  const getMargin = (displayOptions: DisplayBlockOptions | undefined): number => {
    if (!displayOptions) {
      return 0;
    }
    if (displayOptions.margin === undefined) {
      return 0;
    }

    return displayOptions.margin;
  }

  const t = useTranslationCommonFrontEnd();

  const [useBackgroundColor, setUseBackgroundColor] = useState<boolean>(getUseBackgroundFlag(displayOptions));
  const [backgroundColor, setBackgroundColor] = useState<string>(getBackgroundColor(displayOptions));
  const [useBorder, setUseBorder] = useState<boolean>(getUseBorderFlag(displayOptions));
  const [borderWidth, setBorderWidth] = useState<number>(getBorderWidth(displayOptions));
  const [borderColor, setBorderColor] = useState<string>(getBorderColor(displayOptions));
  const [margin, setMargin] = useState<number>(getMargin(displayOptions));

  useEffect(() => {

    const updatedDisplayOptions: DisplayBlockOptions = {
      backgroundColor: useBackgroundColor ? backgroundColor : undefined,
      borderColor: useBorder ? borderColor : undefined,
      borderWidth: useBorder ? borderWidth : undefined,
      margin: useBorder ? margin : undefined
    }

    setDisplayOptions(updatedDisplayOptions);

  }, [useBackgroundColor,
    backgroundColor,
    useBorder,
    borderWidth,
    borderColor,
    margin]);


  return (
    <Form>
      <VerticalListComponent>
        <HorizontalListComponent>
          <label
            className='label'
            htmlFor="backgroundColorActive">{t('backgroundColor')}
          </label>
          <Form.Check
            type="switch"
            id="backgroundColorActive"
            checked={useBackgroundColor}
            onChange={(e) => {
              setUseBackgroundColor(e.target.checked);
            }}
          />
        </HorizontalListComponent>

        {useBackgroundColor &&
          <div className="indent">
            <HorizontalListComponent>
              <label
                className='label'>
                {t('color')}
              </label>
              <ColorPickerComponent
                id="element-configuration-background-color"
                value={backgroundColor}
                numberOfLatestColors={5}
                onColorChange={setBackgroundColor}
                fixPosition={true}
              />
            </HorizontalListComponent>
          </div>
        }

        <HorizontalListComponent>
          <label
            className='label'
            htmlFor="borderActive">{t('border')}
          </label>
          <Form.Check
            type="switch"
            id="borderActive"
            checked={useBorder}
            onChange={(e) => {
              setUseBorder(e.target.checked);
            }}
          />
        </HorizontalListComponent>

        {useBorder &&
          <div className='indent'>

            <VerticalListComponent>
              <HorizontalListComponent>
                <label
                  className='label'
                >
                  {t('width')}
                </label>
                <input
                  type="number"
                  value={borderWidth}
                  onChange={(e) => setBorderWidth(parseInt(e.target.value))}
                  min={1}
                  max={50}
                />
              </HorizontalListComponent>

              <HorizontalListComponent>
                <label className='label'>{t('color')}</label>
                <ColorPickerComponent
                  id="element-configuration-background-color"
                  value={borderColor}
                  numberOfLatestColors={5}
                  onColorChange={setBorderColor}
                  fixPosition={true}
                />
              </HorizontalListComponent>
              <HorizontalListComponent>
                <label
                  className='label'>{t('margin')}
                </label>
                <input
                  type="number"
                  value={margin}
                  onChange={(e) => setMargin(parseInt(e.target.value))}
                  min={0}
                  max={50}
                />
              </HorizontalListComponent>
            </VerticalListComponent>
          </div>
        }

      </VerticalListComponent>
    </Form>
  );
}