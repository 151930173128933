export class ColorSetter {

  public static UpdateColors(
    colorPrimary: string,
    colorBack: string
  ) {

    const root = document.documentElement;

    /* Button */

    root.style.setProperty('--style-var-btn-shadow-focus', `0 0 0 0.25rem ${this.makeTransparent(colorPrimary, 0.5)}`);

    /* Button:hover*/

    root.style.setProperty('--style-var-btn-color-back-hover', this.darkenColor(colorPrimary, 30));

    /* Button Primary */

    root.style.setProperty('--style-var-btn-primary-color-text', this.getContrastColor(colorPrimary));
    root.style.setProperty('--style-var-btn-primary-color-back', colorPrimary);
    root.style.setProperty('--style-var-btn-primary-color-border', colorPrimary);

    root.style.setProperty('--style-var-btn-primary-color-text-hover', this.getContrastColor(this.darkenColor(colorPrimary, 30)));
    root.style.setProperty('--style-var-btn-primary-color-back-hover', this.darkenColor(colorPrimary, 30));
    root.style.setProperty('--style-var-btn-primary-color-border-hover', this.darkenColor(colorPrimary, 50));

    root.style.setProperty('--style-var-btn-primary-color-text-active', this.getContrastColor(this.darkenColor(colorPrimary, 50)));
    root.style.setProperty('--style-var-btn-primary-color-back-active', this.darkenColor(colorPrimary, 50));
    root.style.setProperty('--style-var-btn-primary-color-border-active', this.darkenColor(colorPrimary, 80));

    root.style.setProperty('--style-var-btn-primary-color-text-disabled', this.getContrastColor(this.lightenColor(colorPrimary, 50)));
    root.style.setProperty('--style-var-btn-primary-color-back-disabled', this.lightenColor(colorPrimary, 50));
    root.style.setProperty('--style-var-btn-primary-color-border-disabled', this.lightenColor(colorPrimary, 50));

    /* Button Outline */

    root.style.setProperty('--style-var-btn-outline-color-text', this.darkenColor(colorPrimary, 30));
    root.style.setProperty('--style-var-btn-outline-color-back', 'transparent');
    root.style.setProperty('--style-var-btn-outline-color-border', this.darkenColor(colorPrimary, 30));

    root.style.setProperty('--style-var-btn-outline-color-text-hover', this.getContrastColor(this.darkenColor(colorPrimary, 30)));
    root.style.setProperty('--style-var-btn-outline-color-back-hover', this.darkenColor(colorPrimary, 30));
    root.style.setProperty('--style-var-btn-outline-color-border-hover', this.darkenColor(colorPrimary, 50));

    root.style.setProperty('--style-var-btn-outline-color-text-active', this.getContrastColor(this.darkenColor(colorPrimary, 50)));
    root.style.setProperty('--style-var-btn-outline-color-back-active', this.darkenColor(colorPrimary, 50));
    root.style.setProperty('--style-var-btn-outline-color-border-active', this.darkenColor(colorPrimary, 80));

    root.style.setProperty('--style-var-btn-outline-color-text-disabled', this.getContrastColor(this.lightenColor(colorPrimary, 50)));
    root.style.setProperty('--style-var-btn-outline-color-back-disabled', this.lightenColor(colorPrimary, 50));
    root.style.setProperty('--style-var-btn-outline-color-border-disabled', this.lightenColor(colorPrimary, 50));

    root.style.setProperty('--style-var-toggle-color-back', colorPrimary);
    root.style.setProperty('--style-var-toggle-color-border', this.darkenColor(colorPrimary, 50));

    /* Toggle*/

    root.style.setProperty('--style-var-toggle-box-shadow-focus', `0 0 0 0.25rem ${this.makeTransparent(colorPrimary, 0.5)}`);
    root.style.setProperty('--style-var-toggle-circle', this.generateFormSwitchImage(this.getAsRgb(colorPrimary)));
    root.style.setProperty('--style-var-toggle-circle-checked', this.generateFormSwitchImage(this.getAsRgb('#FFFFFF')));

    /* Form control:Focus */

    root.style.setProperty('--style-var-form-control-color-border-focus', colorPrimary);
    root.style.setProperty('--style-var-form-control-shadow-focus', `0 0 0 0.25rem ${this.makeTransparent(colorPrimary, 0.4)}`);

    /* Dropdown */

    root.style.setProperty('--style-var-dropdown-item-active', colorPrimary);

    /* List Group Item */

    root.style.setProperty('--style-var-list-group-item-selected-color-border', this.darkenColor(colorPrimary, 50));

    /* Colors */

    root.style.setProperty('--color-primary', colorPrimary);
    root.style.setProperty('--color-primary-light', this.lightenColor(colorPrimary, 30));
    root.style.setProperty('--color-primary-dark', this.darkenColor(colorPrimary, 30));

    root.style.setProperty('--color-primary-opposite', this.getContrastColor(colorPrimary));
    root.style.setProperty('--color-primary-light-opposite', this.getContrastColor(this.lightenColor(colorPrimary, 30)));

    /* Background Colors */

    root.style.setProperty('--color-neutral-light', colorBack);
    root.style.setProperty('--color-background', colorBack);
    root.style.setProperty('--color-background-alt1', this.lightenColor(colorBack, 10));
    root.style.setProperty('--color-background-alt2', this.darkenColor(colorBack, 10));

    /* Text Colors */

    const colorBackContrast = this.getContrastColor(colorBack);

    root.style.setProperty('--color-text-primary', colorBackContrast);

    root.style.setProperty('--color-text', colorBackContrast);
    root.style.setProperty('--color-text-alt1', this.lightenColor(colorBackContrast, 30));
    root.style.setProperty('--color-text-alt2', this.darkenColor(colorBackContrast, 30));

    /* Btn Close Pane */

    root.style.setProperty('--style-var-btn-close-image', this.generateClosePaneImage(this.getAsRgb(colorBackContrast)));

    root.style.setProperty('--bs-link-color-rgb', 'white');

    root.style.setProperty('--test-debug', 'red');
  }

  static isDark(color: string): boolean {
    const { r, g, b } = this.getAsComponents(color);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 128;
  }

  static getContrastColor(color: string): string {
    return this.isDark(color) ? '#FFFFFF' : '#000000';
  }

  static lightenColor(colorPrimary: string, amount: number): string {
    let color = colorPrimary.replace(/^#/, '');
    if (color.length === 3) {
      color = color.split('').map(c => c + c).join('');
    }

    const num = parseInt(color, 16);
    let r = (num >> 16) + amount;
    let g = ((num >> 8) & 0x00FF) + amount;
    let b = (num & 0x0000FF) + amount;

    r = r > 255 ? 255 : r;
    g = g > 255 ? 255 : g;
    b = b > 255 ? 255 : b;

    const result = `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
    return result;
  }

  static darkenColor(colorPrimary: string, amount: number): string {
    let color = colorPrimary.replace(/^#/, '');
    if (color.length === 3) {
      color = color.split('').map(c => c + c).join('');
    }

    const num = parseInt(color, 16);
    let r = (num >> 16) - amount;
    let g = ((num >> 8) & 0x00FF) - amount;
    let b = (num & 0x0000FF) - amount;

    r = r < 0 ? 0 : r;
    g = g < 0 ? 0 : g;
    b = b < 0 ? 0 : b;

    const result = `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
    return result;
  }

  static makeTransparent(colorPrimary: string, amount: number): string {
    const { r, g, b } = this.getAsComponents(colorPrimary);
    const result = `rgba(${r},${g},${b},${amount})`;
    return result;
  }

  static getAsComponents(color: string): { r: number, g: number, b: number } {
    let colorStr = color.replace(/^#/, '');
    if (colorStr.length === 3) {
      colorStr = colorStr.split('').map(c => c + c).join('');
    }

    const num = parseInt(colorStr, 16);
    const r = (num >> 16);
    const g = ((num >> 8) & 0x00FF);
    const b = (num & 0x0000FF);

    return { r, g, b };
  }

  static getAsRgb(color: string): string {
    const { r, g, b } = this.getAsComponents(color);
    return `rgb(${r},${g},${b})`;
  }

  static generateFormSwitchImage(color: string): string {


    const svg = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='${color}'/></svg>`;
    const encodedSvg = this.encodeSvg(svg);
    const result = `url(data:image/svg+xml,${encodedSvg})`;

    return result;
  }
  static generateClosePaneImage(color: string): string {
    const svg = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='${color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>`;
    const encodedSvg = this.encodeSvg(svg);
    const result = `url(data:image/svg+xml,${encodedSvg})`;

    return result;
  }

  static encodeSvg(svg: string): string {
    const encodedSvg = encodeURIComponent(svg)
      .replace(/"/g, "'") // Replace double quotes with single quotes
      .replace(/</g, '%3C') // Encode <
      .replace(/>/g, '%3E') // Encode >
      .replace(/#/g, '%23') // Encode #
      .replace(/'/g, '%27') // Encode '
      .replace(/\(/g, '%28') // Encode (
      .replace(/\)/g, '%29') // Encode )
      .replace(/ /g, '%20') // Encode spaces
      .replace(/=/g, '%3D'); // Encode equals

    return encodedSvg;
  }

}