import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Product } from "../../../../common-app/sitePagesStores/models/product";
import { useTranslationCommonFrontEnd } from "../../../translations/translation";
import { VerticalListComponent } from "../../verticalList/verticalList";
import { VerticalWhiteSpaceComponent } from "../../verticalWhiteSpace/verticalWhiteSpace";
import { useDynamicPageDataContext } from "../provider/hooks/useDynamicPageDataContext";
import { ProductExtraInfoComponent } from "./components/productExtraInfo/productExtraInfo";
import { ProductImageGalleryComponent } from "./components/productImageGallery/productImageGallery";
import { ProductPriceAddToCartComponent } from "./components/productPriceAddToCart/productPriceAddToCart";
import { DynamicPageStoreProvider } from "./provider/dynamicPageStoreProvider";

import './dynamicPageStoreItem.css';

interface DynamicPageStoreItemProps {
  item: string;
  dataProvider: DynamicPageStoreProvider;
}

export function DynamicPageStoreItemComponent({
  item,
  dataProvider
}: DynamicPageStoreItemProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const dynamicPageContext = useDynamicPageDataContext();

  const [product, setProduct] = useState<Product | undefined>();

  useEffect(() => {

    dataProvider.getProduct(item).then(product => {
      setProduct(product);
    });
  }, [item, dataProvider, dynamicPageContext.pageId]);

  if (!product) {
    return <div>{t('loading')}</div>
  }

  return (
    <div className="product-item">
      <div
        className="group-label">{product.name}
      </div>

      <VerticalListComponent>

        <div className="gallery-and-description">
          {/* Gallery */}
          <div className="product-gallery">
            <ProductImageGalleryComponent
              product={product}
              dataProvider={dataProvider}
            />
          </div>

          <div className="description-and-price">
            {/* Price and Add to Cart */}
            <ProductPriceAddToCartComponent
              product={product}
              dataProvider={dataProvider}
            />

            {/* Description */}
            <div
              className="product-description"
            >
              {product.description}
            </div>

          </div>
        </div>

        {product.extraInfo !== "" &&
          <div className="product-extra-info">
            <ProductExtraInfoComponent
              product={product} />
          </div>
        }
      </VerticalListComponent>

      <VerticalWhiteSpaceComponent amount={3} />
      <Button onClick={() => window.history.back()}>
        {t('goBack')}
      </Button>
      <VerticalWhiteSpaceComponent amount={3} />
    </div>
  )
}