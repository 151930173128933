import { AppRouter } from './router';
import { UserSessionProvider } from '../components/contexts/session/userSessionProvider';
import { StyleManagerPixelVaidosoComponent } from '../components/sites/rendering/styleManager/styleManagerPixelVaidoso';

import './App.css';


export function AppComponent(): JSX.Element {
  return (
    <div className="app">
      <UserSessionProvider>
        <StyleManagerPixelVaidosoComponent />
        <AppRouter />
        {/* <ColorPickerTester /> */}
      </UserSessionProvider>
    </div>
  );
}
