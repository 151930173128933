import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SitesUtil2 } from "../../common/sites/sitesUtil2";
import { SitePageRenderInfo } from "../../common-app/sites/models/sitePageRenderInfo";
import { SiteEditorComponent } from "../../components/sites/editor/editor/siteEditor/siteEditor";
import { SitePostCreationPopupControllerComponent } from "../../components/sites/rendering/sitePostCreationPopup/sitePostCreationPopupController";
import { ServiceApiSites } from "../../services/api/serviceApiSites";
import { DynamicPageContentsRenderingMapping } from "../../components/sites/rendering/dynamicPageContentsRenderingMapping";
import { DynamicPageRenderer } from './dynamicPageRenderer';
import { SiteAdminControlButtonComponent } from "../../components/sites/siteAdminControlButton/siteAdminControlButton";
import { DefaultUserIdentificationProvider } from "../../services/requestHeaderData/senderIdentification/defaultUserIdentificationProvider";
import { DynamicPageNotFoundComponent } from "./notFound/dynamicPageNotFound";
import { DynamicPageDataProvider } from "../../common-frontend/components/dynamicPages/provider/dynamicPageDataProvider";
import { BackendServerEndpointProvider } from "../../common-frontend/services/api/backendServerEndpointProvider";
import { StyleManagerPixelVaidosoComponent } from "../../components/sites/rendering/styleManager/styleManagerPixelVaidoso";
import { SiteInfo } from "../../common-app/sites/models/siteInfo";

import './dynamicSiteRenderer.css';

export function DynamicSiteRenderer(): JSX.Element {

  const navigate = useNavigate();
  const currentRoute = useLocation();

  // Get the route specific for the user's site
  const pageUrlPart = SitesUtil2.getSitePagePathFromFullUrl(currentRoute.pathname);
  const pageUrlPartParsed = SitesUtil2.getDomainAndPagesFromUrlPart(pageUrlPart);

  // Inferred from the route
  const siteDomain = pageUrlPartParsed.domain;

  // Info from the server
  const [loadingPageInfo, setLoadingPageInfo] = useState(false);
  const [siteInfo, setSiteInfo] = useState<SiteInfo>();
  const [pageRenderInfo, setPageRenderInfo] = useState<SitePageRenderInfo>();

  const [backendServerAddress, setBackendServerAddress] = useState<string>();

  const logRenderingIssue = (issue: string, param1?: string, param2?: string) => {

    // Get current time
    const now = new Date();
    const time = now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();

    console.warn('*'.repeat(40));
    console.warn(` ${time} - DynamicSiteRenderer issue a warning!`);
    console.warn('   Issue: ', issue);

    if (param1 !== undefined) {
      console.warn(`   ${param1}`);
    }
    if (param2 !== undefined) {
      console.warn(`   ${param2}`);
    }
    console.warn('*'.repeat(40));
  }

  const updatePageTitle = (pagePresentation: SitePageRenderInfo | undefined) => {

    if (pagePresentation) {
      document.title = pagePresentation.siteName + " - " + pagePresentation.pageName;
    }
    else {
      document.title = "Pixel Vaidoso";
    }
  }

  useEffect(() => {
    const backendServer = BackendServerEndpointProvider.getDefaultBackendServer();
    setBackendServerAddress(backendServer.getEndpoint());
  }, []);

  useEffect(() => {

    // Ask the server information about this route
    const loadPageInfoFromServer = async () => {

      try {

        // Get information about the current session
        const userIdentificationProvider = new DefaultUserIdentificationProvider();

        // Ask the server for details about the page
        const sitePageRenderInfo = await ServiceApiSites.getPageInfo(
          pageUrlPart,
          userIdentificationProvider.getSessionId(),
          userIdentificationProvider.getDeviceId()
        );

        if (sitePageRenderInfo === undefined) {
          logRenderingIssue("Backend did not return a page info.", pageUrlPart);
          throw new Error("Backend did not return a page info.");
        }

        // Check if this is admin, but the site is not available for editing
        if (sitePageRenderInfo.isPathToSiteEditor === true && sitePageRenderInfo.isUserAllowedToEditSite === false) {
          navigate('/signin');
          return;
        }

        ServiceApiSites.getSiteInfo(sitePageRenderInfo.siteId).then((siteInfo) => {
          setSiteInfo(siteInfo);
        });

        setPageRenderInfo(sitePageRenderInfo);
        updatePageTitle(sitePageRenderInfo);
        setLoadingPageInfo(false);
      }
      catch (error) {
        // Log the error
        console.error(error);

        // Reset the state
        setPageRenderInfo(undefined);
        setLoadingPageInfo(false);
      }
    }

    setLoadingPageInfo(true);
    loadPageInfoFromServer();

  }, [currentRoute]);

  const ComponentToRender = pageRenderInfo ? DynamicPageContentsRenderingMapping.getPageContentsRenderComponent(pageRenderInfo.pageType) : undefined;

  // Helper vars
  const siteId = pageRenderInfo?.siteId;
  const pageId = pageRenderInfo?.pageId;
  const isCurrentUserAllowedToEditSite = pageRenderInfo?.isUserAllowedToEditSite;
  const isPathToSiteEditor = pageRenderInfo?.isPathToSiteEditor;
  const pageNotFound = pageRenderInfo === undefined;

  return (
    <div className="dynamic-site-renderer">

      {siteId &&
        <SitePostCreationPopupControllerComponent
          siteId={siteId}
        />
      }

      {/* Site Admin Button */}
      {isPathToSiteEditor === false && isCurrentUserAllowedToEditSite === true &&
        <SiteAdminControlButtonComponent />
      }

      {/* Editor Rendering */}
      {isPathToSiteEditor === true && siteId &&
        <>
          <StyleManagerPixelVaidosoComponent />

          <SiteEditorComponent
            site={siteDomain}
            siteId={siteId}
          />
        </>
      }

      {/* Page Rendering */}
      {isPathToSiteEditor === false &&
        pageId &&
        siteId &&
        ComponentToRender &&
        pageRenderInfo &&
        siteInfo &&
        backendServerAddress &&

        <DynamicPageDataProvider
          siteId={siteId}
          pageId={pageId}
          backendServerAddress={backendServerAddress}
        >
          <DynamicPageRenderer
            siteInfo={siteInfo}
            pageRenderInfo={pageRenderInfo}
            ComponentToRender={ComponentToRender}
          />
        </DynamicPageDataProvider>
      }

      {/* Page Not Found */}
      {pageNotFound && loadingPageInfo === false &&
        <DynamicPageNotFoundComponent
          fullPath={currentRoute.pathname}
          siteDomain={pageUrlPartParsed.domain}
          pagePath={pageUrlPart}
        />
      }
    </div>
  );
}